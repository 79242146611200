// General Text
// #region Home
const textLanding = `Bem vind@ ao nosso website. 
Com o nosso lema "Simplificar para Capacitar", estamos aqui em busca de ser o teu farol na complexidade deste mundo dos investimentos e das finanças pessoais, oferecendo-te conhecimento, ferramentas e estratégias com uma linguagem que se adequa a todos os níveis 
de investidor, e que te capacitem na tomada de decisões financeiras informadas.
A nossa missão vai além de ser apenas mais uma página de instagram, pelo que aqui a aprendizagem é contínua e o conhecimento adquirido será a chave para a tua independência financeira.`;

const textInstaPost = `Se quiseres saber mais sobre o nosso trabalho, passa pelo nosso Instagram, que é a nossa plataforma principal.
Nele, encontrarás 3 publicações semanais: duas publicações aleatórias às terças-feiras e quintas-feiras, e uma análise de Ação/ETF/REIT ou um duelo de empresas aos sábados.

Despertámos o teu interesse? Clica na imagem que nós levamos-te lá!`;

const textCalculator = `Queres saber quanto é que o teu investimento pode render? Com esta calculadora descobres o <u>Montante Final</u> que poderás alcançar e ajustas as <u>Contribuições Adicionais</u>, a <u>Taxa de Retorno</u>, o <u>Montante Inicial</u> ou o <u>Tempo</u> necessários para chegar aos objetivos que tens em mente. 
Seja qual for a tua estratégia, esta ferramenta ajuda-te a planear e otimizar os teus investimentos. Simplifica as tuas decisões e garante que estás no caminho certo para alcançar os teus objetivos financeiros!`;

const textPatreon = [
    `No nosso Patreon tens acesso a 2 tiers de subscrição.
    Conhece mais sobre os benefícios de cada tier aqui em baixo.`,
];

const patreonT1Advantages = [
    `Contribuir para o desenvolvimento da página`,
    `Tabela de valores intrínsecos atualizados trimestralmente`,
    `Acesso antecipado às publicações do instagram`,
    `Tabela mensal com principais eventos do mês e ex-dividend dates`,
    `Descontos exclusivos Tier 1 em produtos e serviços`,
    `Sorteio voucher 20€ por mês por cada 100 patronos no Tier 1`
];

const patreonT2Advantages = [
    `Acesso a  todos os benefícios do Tier 1`,
    `Acesso à minha watch-list (com preços de compra)`,
    `Conta de investimentos do 0 onde partilho compras e vendas do mês`,
    `Análise detalhada de ações (Análise Qualitativa, Análise Quantitativa e Valor Intrínseco)`,
    `Descontos exclusivos Tier 2 em produtos e serviços`,
    `Descontos exclusivos Tier 1 em produtos e serviços`,
    `Sorteio voucher 50€ mês por cada 100 patronos no Tier 2`
];

const textPartners = `Aqui em baixo estão algumas das aplicações, plataformas e websites que utilizamos, onde te podes inscrever e ganhar um bónus extra.`;
// #endregion Home

// #region Blog
// #endregion Blog

// #region Posts
// #endregion Posts

// #region Products
const productsDesc = `Pronto para transformar a tua vida financeira? Aqui encontras ferramentas práticas e educativas para simplificar o mundo dos investimentos e das finanças pessoais. Desde materiais educativos a ferramentas de controlo financeiro, temos recursos valiosos para todos os níveis de experiência.
Quer estejas a começar ou a querer melhorar as tuas habilidades, tudo aqui foi criado para fortalecer as tuas decisões financeiras. Se tiveres dúvidas, fala connosco. Estamos aqui para te apoiar na tua jornada!`;

const ebookDesc = `Queres investir em ETFs mas ainda tens dúvidas? Este eBook é aquilo que procuras! Em 8 capítulos e 19 páginas, vais descobrir tudo o que precisas saber sobre ETFs de forma simples e direta.
Aprende o que são, como funcionam, e como escolher os melhores para ti. Explora algumas estratégias práticas para maximizares os teus retornos e entende as vantagens e riscos destes produtos. Também vais ficar a par das últimas tendências e oportunidades.
Lê este eBook e fica pronto para dar o próximo passo na tua jornada de investimento. Investir com confiança e sabedoria só depende de ti!`;

const manualDesc = `Queres investir em ETFs mas ainda tens dúvidas? Este eBook é aquilo que procuras! Em 8 capítulos e 19 páginas, vais descobrir tudo o que precisas saber sobre ETFs de forma simples e direta.
Aprende o que são, como funcionam, e como escolher os melhores para ti. Explora algumas estratégias práticas para maximizares os teus retornos e entende as vantagens e riscos destes produtos. Também vais ficar a par das últimas tendências e oportunidades.
Lê este eBook e fica pronto para dar o próximo passo na tua jornada de investimento. Investir com confiança e sabedoria só depende de ti!`;
 
const portfolioDesc = `Este Excel oferece-te uma visão completa e automatizada do teu portefólio de investimentos (incluíndo ações, ETFs, Criptomoedas, P2P, Certificados de Aforro,... , um calendário de dividendos automatizado para planeares as tuas receitas com antecedência e uma Watch-List dinânima para estares de olho em futuras aquisições. Tudo isto com atualizações das cotações em tempo real. 
Desenvolvido para simplificar a tua gestão financeira, este Excel proporciona ferramentas poderosas para que possas seguir, analisar e otimizar os teus investimentos.`;

const bundleDesc = `Este pack foi construído para ti, que queres ter um maior controlo sobre os teus investimentos, com uma visão completa e automatizada do teu portefólio de investimentos, um Calendário de Dividendos automatizado para planeares as tuas receitas com antecedência e uma Watch-List dinânima para estares de olho em futuras aquisições, mas também para ti que procuras uma abordagem simplificada e intuitiva na avaliação do Valor Intrínseco da﻿s ações. Tudo isto com atualizações das cotações em tempo real. 

Com ele vais obter todos os benefícios presentes nas duas folhas, bem como um desconto exclusivo no Ebook de ETFs.`;

const calcDesc = `Projetado com a simplicidade que nos caracteriza, neste Excel vais encontrar 9 páginas +95% automatizadas com o objetivo de te oferecer uma abordagem simplificada e intuitiva na avaliação do valor intrínseco das ações, visando auxiliar os investidores iniciantes nesta jornada financeira, permitindo que possas dedicar mais tempo a uma análise mais aprofundada de cada empresa.
Encontras também uma página 100% automatizada com dados retirados da internet em tempo real, que te oferece mais de 20 métricas financeiras da empresa que estás a analisar, incluindo rácios, informações relativas a dividendos, médias de movimento, mínimos e máximos, retornos, previsões de analistas, e muito mais.`;
// #endregion Products

// #region Footer
const investWarn = `Investir é arriscado e podes perder parte ou todo o capital investido.
A informação fornecida é apenas para fins informativos e educacionais e não representa qualquer tipo de aconselhamento financeiro e/ou recomendação de investimento`;
// #endregion Footer

export {
    textLanding,
    textInstaPost,
    textCalculator,
    textPatreon,
    patreonT1Advantages,
    patreonT2Advantages,
    textPartners,
    productsDesc,
    ebookDesc,
    manualDesc,
    portfolioDesc,
    bundleDesc,
    calcDesc,
    investWarn,
};